@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins-bold';
  src: url('./assets//fonts/Poppins/Poppins-Bold.ttf');
}

body {
  font-family: 'Poppins';
}
p {
  font-size: small;
}
.test {
  border: 1px solid red;
}




/* Menu animation */
.hamburger{
  width: 20px;
  height: 2px;
  background-color: #ccc;
  border-radius: 5px;
  z-index: 10;
  transition: all .5s ease;
}
.hamburger::before,
.hamburger::after{
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        background: #ccc;
        border-radius: 5px;
        transition: all .5s ease;
}

.hamburger::before{
    transform: translateY(-6px);
}

.hamburger::after{
    transform: translateY(6px);
}

.hamburger.active {
  transform: translateX(-50px);
  background: transparent;
}
.hamburger.active::before{
  transform: rotate(45deg) translate(35px, -35px);
}

.hamburger.active::after{
  transform: rotate(-45deg) translate(35px,35px);
}

