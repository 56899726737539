@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  h1 {
    @apply text-3xl font-[Poppins-bold];
  }

  .btn1 {
    @apply bg-primary text-secondary p-2 text-sm px-4;
  }
  .btn1.light {
    @apply bg-secondary text-primary;
  }
}